import { Text } from '@shared/components';
import { Dropdown } from '@shared/components/plugin-components';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { useAuthContext } from '@shared/contexts/useAuthContext';
import { useNavigate } from 'react-router-dom';

const Menu = () => {
  const { isAuthenticated, initializeLoginDialog, logout } = useAuthContext();
  const { hasQuotes } = useMeContext();
  const navigate = useNavigate();

  const getItems = () => {
    if (!isAuthenticated) {
      return [{ node: <div onClick={initializeLoginDialog}>Log in</div> }];
    }

    return [
      ...(hasQuotes
        ? [
            {
              node: (
                <div
                  className="py-2"
                  onClick={() => {
                    navigate('/quotes');
                  }}
                >
                  <Text type="body-xs">Quotes</Text>
                </div>
              ),
            },
          ]
        : []),
      {
        node: (
          <div
            className="py-2"
            onClick={() => {
              navigate('/settings');
            }}
          >
            <Text type="body-xs">Account settings</Text>
          </div>
        ),
      },
      {
        node: (
          <div onClick={logout} className="py-2">
            <Text type="body-xs">Log out</Text>
          </div>
        ),
      },
    ];
  };

  return <Dropdown items={getItems()} />;
};
export default Menu;
