import styleBase from '@/styles/all.scss?inline';
import { useEffect, useRef, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import ShadowRoot from 'react-shadow-root';
import { Panel } from '../../panel/Panel';
import style from './index.css?inline';

function GmailSideBarApp() {
  const [threadId, setThreadId] = useState('');
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== 'https://mail.google.com') {
        return;
      }

      const messageData = event.data;

      switch (messageData.op) {
        case 'SET_THREAD':
          setThreadId(messageData.data);
          break;
        case 'CLEAR_THREAD':
          setThreadId('');
      }
    };

    // Listen for the message event
    window.addEventListener('message', handleMessage);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div className="App" id="vooma-chrome-extension">
      <style>
        {`.vooma-compose-button {
          opacity: 1 !important;
        }`}
      </style>
      <ShadowRoot>
        <style>{style}</style>
        <style>{styleBase}</style>
        <div ref={rootRef}>
          <Routes>
            <Route
              path="*"
              element={<Panel threadId={threadId} rootRef={rootRef.current} />}
            />
          </Routes>
        </div>
      </ShadowRoot>
    </div>
  );
}

export default GmailSideBarApp;
