import { useCreateJobMutation } from '@/hooks/useCreateJobMutation';
import { useGmailJobs } from '@/hooks/useGmailJobs';
import { useGmailReplyAll } from '@/hooks/useGmailReplyAll';
import { EmailThreadContext } from '@shared/contexts/pluginContexts/EmailThreadContext';
import { JobType } from '@shared/generated/graphql';
import { JobEmailMetadata } from '@shared/types/job';

type GmailThreadContextProviderProps = {
  threadId: string;
  children: JSX.Element;
};
export const GmailThreadContextProvider = ({
  threadId,
  children,
}: GmailThreadContextProviderProps) => {
  const createOrderJob = useCreateJobMutation();
  const createQuoteJob = useCreateJobMutation();
  const threadJobs = useGmailJobs({ threadId });
  const reply = useGmailReplyAll();

  const createEmailMetadata = (): JobEmailMetadata => {
    return {
      itemId: threadId,
      // messageId: lastMsg?.id,
      // subject: lastMsg?.subject,
      // sender: me?.email ?? '',
      // replyTo: me?.email ?? '',
      // originatorAddress: lastMsg?.from?.handle ?? '',
    };
  };

  const createOrderFromThread = async () => {
    const ret = await createOrderJob.createJob({
      emailMetadata: createEmailMetadata(),
      jobType: JobType.ORDERS,
      threadId,
    });
    return ret.data?.createGmailJob.job;
  };

  const createQuoteFromThread = async () => {
    const ret = await createQuoteJob.createJob({
      emailMetadata: createEmailMetadata(),
      jobType: JobType.QUOTES,
      threadId,
    });
    return ret.data?.createGmailJob.job;
  };

  const createOrder = {
    call: createOrderFromThread,
    error: createOrderJob.error,
    loading: createOrderJob.loading,
  };

  const createQuote = {
    call: createQuoteFromThread,
    error: createQuoteJob.error,
    loading: createQuoteJob.loading,
  };

  const sharedInbox = {
    isSharedInbox: false,
    candidates: [],
    selectCandidate: () => {},
  };

  const thisItem = {
    id: threadId,
    sender: {
      emailAddress: 'TODO(mike)',
    },
  };

  function openThread(emailMetadata: JobEmailMetadata) {
    window.location.assign(
      `https://mail.google.com/mail/u/0/#inbox/${emailMetadata.itemId}`
    );
    // NOTE(Martin): This is necessary for gmail to pick up the new thread
    window.location.reload();
  }

  return (
    <EmailThreadContext.Provider
      value={{
        thisItem,
        threadJobs,
        createOrder,
        createQuote,
        sharedInbox,
        reply,
        openThread,
      }}
    >
      {children}
    </EmailThreadContext.Provider>
  );
};
