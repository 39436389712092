import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import {
  JobSummaryFragment,
  JobType,
  useGetJobsQuery,
} from '@shared/generated/graphql';
import { filterJobsToDisplay } from '@shared/plugin/utils/optimisticJobs';
import { useEffect, useMemo, useState } from 'react';

type GmailJobsState = {
  jobs: JobSummaryFragment[];
  jobTypes: Record<string, JobType>;
  storeJobType: (jobType: JobType, jobId?: string) => void;
  loading: boolean;
};

const POLL_INTERVAL_FREQUENT_SECONDS = 3 * 1000;
const POLL_INTERVAL_INFREQUENT_SECONDS = 30 * 1000;

type UseGmailJobsProps = { threadId: string };
export const useGmailJobs = (p: UseGmailJobsProps): GmailJobsState => {
  const [jobTypes, setJobTypes] = useState<Record<string, JobType>>({});
  const { defaultOrgId } = useMeContext();
  const { threadId } = p;
  const { data, startPolling, stopPolling, loading } = useGetJobsQuery({
    variables: {
      input: {
        organizationIds: defaultOrgId ? [defaultOrgId] : [],
        gmailFilter: { threadId },
      },
    },
    skip: !threadId,
    pollInterval: POLL_INTERVAL_INFREQUENT_SECONDS,
  });

  const storeJobType = (jobType: JobType, jobId?: string) => {
    if (!jobId) return;

    setJobTypes((prev) => ({
      ...prev,
      [jobId]: jobType,
    }));
  };

  const jobs = useMemo(() => {
    const jobs = data?.jobs.edges.map((edge) => edge.node);
    if (!jobs) return [];
    return filterJobsToDisplay(jobs);
  }, [data]);

  useEffect(() => {
    const jobEdges = data?.jobs.edges;
    if (jobEdges) {
      // NOTE(parlato): This is incorrect - we're currently polling frequently whenever
      // a job exists; this should rather be based on the job's quote and order statuses
      const shouldPollFrequently = jobEdges.length > 0;
      if (shouldPollFrequently) {
        startPolling(POLL_INTERVAL_FREQUENT_SECONDS);
      } else {
        startPolling(POLL_INTERVAL_INFREQUENT_SECONDS);
      }
    }
    return () => {
      stopPolling();
    };
  }, [data, startPolling, stopPolling]);

  return {
    jobs,
    jobTypes,
    storeJobType,
    loading,
  };
};
