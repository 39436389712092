import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import GmailSideBarApp from './pages/content/gmail/SideBarApp';

const container = document.getElementById('root') as HTMLElement;

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [],
  enabled: true,
  enableTracing: true,
  environment: import.meta.env.VITE_ENV,
});

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <GmailSideBarApp />
    </BrowserRouter>
  </StrictMode>,
  container
);
