import { ChromeAPIContextProvider } from '@/context/ChromeAPIContextProvider';
import { GmailThreadContextProvider } from '@/context/GmailThreadContextProvider';
import { ChromeLogin as Login } from '@/features/Login';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { ErrorBoundary } from '@sentry/react';
import { AuthorizeGmailNotice } from '@shared/components/authorizeInstructions/AuthorizeGmailNotice';
import { GlobalErrorBoundary } from '@shared/components/GlobalErrorBoundary';
import PrivateRoute from '@shared/components/PrivateRoute/PrivateRoute';
import { AnalyticsContextProvider } from '@shared/contexts/AnalyticsContext';
import { ApolloWrapper } from '@shared/contexts/ApolloWrapper';
import { AuthContextProvider } from '@shared/contexts/AuthContext';
import { useMe } from '@shared/contexts/hooks/useMe';
import { IntercomSupportContext } from '@shared/contexts/IntercomSupportContext';
import { MeContextProvider } from '@shared/contexts/MeContext';
import { AuthorizedIntegrationProvider } from '@shared/contexts/pluginContexts/AuthorizedIntegrationContext';
import { StandardLogosContextProvider } from '@shared/contexts/pluginContexts/StandardLogosContextProvider';
import { AutomaticNavigator } from '@shared/features/automatic-navigator/AutomaticNavigator';
import QuoteAuditTrail from '@shared/features/quote-trail/QuoteAuditTrail';
import { AuthTokenType, VoomaApiClient } from '@shared/generated/graphql';
import { FlowsViewProvider } from '@shared/plugin/contexts/FlowsViewProvider';
import { PlaceSidePanelContextProvider } from '@shared/plugin/contexts/PlaceSidePanelContext';
import { ToastProvider } from '@shared/plugin/contexts/ToastProvider';
import { AccountSettings } from '@shared/plugin/pages/AccountSettings';
import { Home } from '@shared/plugin/pages/Home';
import { OrderSidePanel } from '@shared/plugin/pages/OrderSidePanel';
import { OriginatorOnboarding } from '@shared/plugin/pages/OriginatorOnboarding';
import { Quotes } from '@shared/plugin/pages/Quotes';
import { QuoteSidePanel } from '@shared/plugin/pages/QuoteSidePanel';
import { DrawerTabValue } from 'clerk_common/constants/urls/queryParams';
import { ORDERS_ROUTE, QUOTES_ROUTE } from 'clerk_common/constants/urls/routes';
import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Layout } from '../../Layout';

const WEB_BASE_URL = import.meta.env.VITE_WEB_BASE_URL;
const LOGIN_URL = `${import.meta.env.VITE_APP_BASE_URL}/login`;
const APOLLO_URI = `${import.meta.env.VITE_API_BASE_URL}/api`;

type PanelProps = {
  threadId: string;
  rootRef: HTMLDivElement | null;
};
export const Panel = ({ threadId, rootRef }: PanelProps) => {
  const location = useLocation();

  return (
    <AnalyticsContextProvider apiClient={VoomaApiClient.CHROME}>
      <ErrorBoundary fallback={GlobalErrorBoundary}>
        <AnimatePresence>
          <ToastProvider rootRef={rootRef}>
            <Auth0ContextProvider>
              <ApolloWrapper appName="chrome" httpLinkUri={APOLLO_URI}>
                <MeContextProvider useMe={useMe} enableFullStory={false}>
                  <IntercomSupportContext.Provider
                    value={{ isInitialized: false, displayLauncher: () => {} }}
                  >
                    <AuthorizedIntegrationProvider
                      type={AuthTokenType.GMAIL_API}
                      renderAuthInstructions={() => <AuthorizeGmailNotice />}
                    >
                      <ChromeAPIContextProvider>
                        <StandardLogosContextProvider>
                          <GmailThreadContextProvider threadId={threadId}>
                            <PlaceSidePanelContextProvider>
                              <FlowsViewProvider>
                                <AutomaticNavigator>
                                  <Routes
                                    location={location}
                                    key={location.pathname}
                                  >
                                    <Route
                                      path="/login-prompt"
                                      element={<Login />}
                                    />
                                    {/* Private routes */}
                                    <Route
                                      path="/"
                                      element={
                                        <PrivateRoute redirectTo="/login-prompt">
                                          <Layout
                                            webBaseUrl={WEB_BASE_URL}
                                            hasMenu
                                          >
                                            <Home />
                                          </Layout>
                                        </PrivateRoute>
                                      }
                                    />
                                    <Route
                                      path="/settings"
                                      element={
                                        <PrivateRoute redirectTo="/login-prompt">
                                          <Layout webBaseUrl={WEB_BASE_URL}>
                                            <AccountSettings />
                                          </Layout>
                                        </PrivateRoute>
                                      }
                                    />
                                    <Route
                                      path={QUOTES_ROUTE}
                                      element={
                                        <PrivateRoute redirectTo="/login-prompt">
                                          <Layout webBaseUrl={WEB_BASE_URL}>
                                            <Quotes webBaseUrl={WEB_BASE_URL} />
                                          </Layout>
                                        </PrivateRoute>
                                      }
                                    />
                                    <Route
                                      path={`${QUOTES_ROUTE}/:quoteId`}
                                      element={
                                        <PrivateRoute redirectTo="/login-prompt">
                                          <QuoteSidePanel
                                            webBaseUrl={WEB_BASE_URL}
                                          />
                                        </PrivateRoute>
                                      }
                                    />
                                    <Route
                                      path={`${QUOTES_ROUTE}/:quoteId/${DrawerTabValue.AUDIT_TRAIL}`}
                                      element={
                                        <PrivateRoute redirectTo="/login-prompt">
                                          <Layout webBaseUrl={WEB_BASE_URL}>
                                            <QuoteAuditTrail />
                                          </Layout>
                                        </PrivateRoute>
                                      }
                                    />
                                    <Route
                                      path={`${ORDERS_ROUTE}/:orderId/originator-onboarding/:originatorId`}
                                      element={
                                        <PrivateRoute redirectTo="/login-prompt">
                                          <Layout webBaseUrl={WEB_BASE_URL}>
                                            <OriginatorOnboarding />
                                          </Layout>
                                        </PrivateRoute>
                                      }
                                    />
                                    <Route
                                      path={`${ORDERS_ROUTE}/:orderId`}
                                      element={
                                        <PrivateRoute redirectTo="/login-prompt">
                                          <OrderSidePanel
                                            webBaseUrl={WEB_BASE_URL}
                                          />
                                        </PrivateRoute>
                                      }
                                    />
                                  </Routes>
                                </AutomaticNavigator>
                              </FlowsViewProvider>
                            </PlaceSidePanelContextProvider>
                          </GmailThreadContextProvider>
                        </StandardLogosContextProvider>
                      </ChromeAPIContextProvider>
                    </AuthorizedIntegrationProvider>
                  </IntercomSupportContext.Provider>
                </MeContextProvider>
              </ApolloWrapper>
            </Auth0ContextProvider>
          </ToastProvider>
        </AnimatePresence>
      </ErrorBoundary>
    </AnalyticsContextProvider>
  );
};

type Auth0ContextProviderProps = {
  children: JSX.Element;
};
function Auth0ContextProvider({ children }: Auth0ContextProviderProps) {
  return (
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      }}
    >
      <AuthProviderAdapter>{children}</AuthProviderAdapter>
    </Auth0Provider>
  );
}

function AuthProviderAdapter({ children }: { children: JSX.Element }) {
  const { logout } = useAuth0();

  const authLogout = async () => {
    await logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };

  return (
    <AuthContextProvider loginUrl={LOGIN_URL} authLogout={authLogout}>
      {children}
    </AuthContextProvider>
  );
}
