import {
  JobSource,
  JobType,
  useCreateJobFromGmailMutation,
} from '@/generated/graphql';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { JobEmailMetadata } from '@shared/types/job';

interface CreateJobPops {
  jobType: JobType;
  emailMetadata: JobEmailMetadata;
  threadId: string;
}

export const useCreateJobMutation = () => {
  const { me, defaultOrgId } = useMeContext();
  const [createJobFromGmail, { loading, error }] =
    useCreateJobFromGmailMutation();

  const createJob = ({ emailMetadata, jobType, threadId }: CreateJobPops) => {
    if (!defaultOrgId) throw new Error('No default org id');

    return createJobFromGmail({
      variables: {
        input: {
          organizationId: defaultOrgId,
          userId: me?.id,
          emailMetadata,
          gmailMessageData: { threadId },
          jobType,
          jobSource: JobSource.GMAIL,
        },
      },
      refetchQueries: ['GetJobs'],
    });
  };

  return {
    createJob,
    loading,
    error,
  };
};
