import {
  useCreateGmailReplyAllMutation,
  useSendGmailMessageMutation,
} from '@/generated/graphql';
import { useToast } from '@shared/components/toast';
import { useAnalytics } from '@shared/hooks/useAnalytics';

type UseReplyAllState = {
  loadingDraftReplyAll: boolean;
};

type UseReplyAllActions = {
  draftReplyAll: (itemId: string, content?: string) => Promise<void>;
};

export const useGmailReplyAll = (): UseReplyAllState & UseReplyAllActions => {
  const { sendToast } = useToast();
  const [draftReplyAllMutation, { loading: loadingDraftReplyAll }] =
    useCreateGmailReplyAllMutation();
  const [sendMessageMutation] = useSendGmailMessageMutation();
  const { track } = useAnalytics();

  const sendMessage = async (messageId: string) => {
    const res = await sendMessageMutation({
      variables: {
        input: { draftId: messageId },
      },
    });
    if (!res.data?.sendGmailMessage?.success) {
      track('Failed to Send Reply All');
      sendToast('Failed to send message', {
        description: 'Something went wrong sending your message.',
        variant: 'error',
      });
    } else {
      track('Sent Reply All');
      sendToast('Success!', {
        description: 'Your message was sent.',
        variant: 'success',
      });
    }
  };

  const draftReplyAll = async (itemId: string, content?: string) => {
    const res = await draftReplyAllMutation({
      variables: {
        input: {
          threadId: itemId,
          content,
        },
      },
    });

    const draftMessageId = res.data?.createGmailReplyAll.messageId;

    if (!res.data?.createGmailReplyAll?.success) {
      sendToast('Failed to compose message', {
        description: 'Something went wrong drafting your message.',
        variant: 'error',
      });
    } else if (draftMessageId) {
      sendToast('Success!', {
        description: 'Your message was drafted.',
        variant: 'success',
        autoHideDuration: 7 * 1000,
        actions: [
          {
            label: 'Send message',
            onPress: () => {
              sendMessage(draftMessageId);
            },
          },
        ],
      });
    }
  };

  return { loadingDraftReplyAll, draftReplyAll };
};
