import { APIContext } from '@shared/contexts/pluginContexts/APIContext';
import { useAuthContext } from '@shared/contexts/useAuthContext';

type ChromeAPIContextProviderProps = {
  children: JSX.Element;
};
export const ChromeAPIContextProvider = ({
  children,
}: ChromeAPIContextProviderProps) => {
  const { token } = useAuthContext();
  const apiURL = import.meta.env.VITE_API_BASE_URL;
  if (!apiURL) {
    throw new Error('API URL is not defined');
  }
  function apiResolver() {
    return {
      url: apiURL,
      headers: {
        Authorization: token || '',
      },
    };
  }
  return (
    <APIContext.Provider
      value={{
        apiResolver,
      }}
    >
      {children}
    </APIContext.Provider>
  );
};
